.accordion.modular-filters {
    border: 1px solid #e1e4e8;
    border-radius: 4px 4px 0 0 ;

    .accordion-panel:not(:last-child) {
        border-bottom: 1px solid #e1e4e8;
    }

    .accordion-panel-content-wrapper {
        padding-bottom: spacer(4);
    }
}

div.modular-filters + div {
    .panel {
        border-top: 0;
        border-radius: 0 0 4px 4px;
    }
}

// TODO why isn't this in AXL?
.inside-select-label {
    .form-control,
    label.control-label {
        font-size: 16px !important;
        z-index: 1 !important;
    }

    label {
        position: absolute;
        padding: 12px;
    }

    select {
        padding-left: 78px;
        margin-right: 16px;
    }
}