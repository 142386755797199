[data-cmp="alphaShowcase"] {
    a {
      overflow: hidden;
    }
  
    h2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .inventory-listing { 
      ul li span{
        padding: spacer(1) 0;
      }
    }
  
    .panel {
      overflow: hidden;
    }
  
    .alpha-sidebar {
      width: 266px;
      flex-shrink: 0;
    }
  
    @media (max-width: $screen-xs-max) {
      .alpha-sidebar {
        width: auto !important;
      }
    }
  }
