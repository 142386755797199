.radio-toggle .boxedRadio { /* stylelint-disable-line selector-class-pattern -- legacy requirement */
    input[type="radio"] {
        display:none;
    }

    input[type="radio"] + label {
        background: transparent;
        display: inline-block;
        height: 35px;
        text-indent: 0;
        text-align: center;
        border: 1px solid #bec8d3;
        border-radius: 0 !important;
        padding: 6px 15px 0;
        font-weight: bold;
    }

    input[type="radio"] + label:not(:last-of-type) {
        border-right: none;
    }

    input[type="radio"]:checked + label{
        font-weight: bold;
        background: #e6e6e6;
    }

    input[type="radio"] + label:active{
        background: #fff;
    }

    input[type="radio"]:hover + label{
        background: #e6e6e6;
    }
}


.contact-page {
    .circle-container {
        background-color: #434E59;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 8px;
        box-sizing: border-box;
        text-align: center;
    }

    .circle-text {
        color: white;
        display: inline-block;
        vertical-align: middle;
    }

    .radio-toggle .boxedRadio .optin-radio { /* stylelint-disable-line selector-class-pattern -- legacy requirement */
        input[type="radio"]+label{
            width: 220px;
            height: 50px;
            padding-top: 6px;
            border-radius: 50px !important;
        }

        input[type="radio"]:checked + label{
            font-weight: bold;
            background: #2f3a47; /* Fallback for browsers that don't support CSS gradients */
            background: linear-gradient(to bottom, #434e59 0, #7e8b98 33%, #94a2b0 66%);
            color: #fff;
        }
    }
}
