[data-cmp="mediaGalleryModal"] {
  z-index: 10030; // override bootstrap style to make this modal behind the check availability modal
  overflow-y: hidden !important;

  // Override some styles for reverse button
  // because treatment is one-off for expand
  .btn-reverse.btn-expand {
    border: 0;
    border-radius: 0;
  }

  // Implement inverted modal styling
  &.inverted {
    button.close {
      opacity: .6;
      color: $white;
      text-shadow: unset;

      &:hover,
      &:focus {
        opacity: 1;
        color: inherit;
      }
    }
  }

  // Custom padding sizing for this modal instance
  .modal-header,
  .modal-body {
    padding: spacer(3);

    @include media-breakpoint-up(sm) {
      padding: spacer(5);
    }
  }

  .modal-dialog {
    margin: 0 auto !important;
    height: 100%;
  }

  .modal-content {
    height: 99%;
    margin: 1% auto;
    display: flex;
    flex-direction: column;

    &>div {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .modal-body {
    padding-top: 0;
    flex: 1; // fit the remaining height
    display: flex;
    flex-direction: column;
  }

  .nav-tabs-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .nav-wrapper a {
    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -4px;
    }
  }

  .tab-content {
    flex: 1; // fit the remaining height
    margin-top: spacer(5);

    &>.tab-pane {
      height: 100%;

      &>.modal-scroll-panel {
        height: 100%;
        position: relative;
        max-height: unset;


        &>.media-content-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: grid;
          grid-template-columns: 100%;
          gap: spacer(1);
        }

        &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: rgb(235 235 235);
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #c0c0c0;
          border-radius: 6px;

          &:active {
            background: #939393;
          }

          &:hover {
            background: #939393;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-content {
      height: 90%;
      margin: 5% auto;
    }
  }
}

[data-cmp="fullscreenViewer"] {
  z-index: 10031;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000000e6;
  display: flex;
  align-items: center;

  .action-bar,
  .carousel-indicator {
    position: absolute;
  }

  .action-bar {
    top: 0;

    // preserve interaction when carousel covers
    z-index: 1;
  }

  .carousel-indicator {
    bottom: 0;
  }

  .image-carousel {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    width: 100%;
    height: 100%;
    transition: left 0.3s;
    position: absolute;
    left: 0;
  }

  .carousel-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  [data-cmp="jwPlayer"] {
    width: 100%;
  }

  .control-button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &>button {
      border-width: 1px;
      border-style: solid;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      box-shadow: rgb(0 0 0 / 15%) 0 10px 20px 0;
      margin: 24px;
      background: transparent;
      padding: 0;
      position: relative;
      border-color: $gray-600;
      color: $white;

      &:disabled {
        opacity: 0.35;
      }

      &:active {
        background: $gray-alpha-85;
      }
    }
  }
}