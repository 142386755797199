// TODO (future scope) introduce utility classes to handle
// something like this without resorting to columns
#sticky-header-vdp {
    .btn {
        max-width: 58.33vw;
    }    

    .display-flex>.btn:only-child,
    :only-child > .btn {
        max-width: 59vw;
    }

    @include media-breakpoint-up('sm') {
        .btn {
            max-width: auto;
        }    

        .display-flex>.btn:only-child,
        :only-child > .btn {
            max-width: auto;
        }
    }
}