/* roboto-regular - latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('#{$font-path}/atc/roboto-v30-latin-regular.woff2') format('woff2'),
  url('#{$font-path}/atc/roboto-v30-latin-regular.woff') format('woff');
}

/* roboto-700 - latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('#{$font-path}/atc/roboto-v30-latin-700.woff2') format('woff2'),
  url('#{$font-path}/atc/roboto-v30-latin-700.woff') format('woff');
}

/* roboto-900 - latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(''),
  url('#{$font-path}/atc/roboto-v30-latin-900.woff2') format('woff2'),
  url('#{$font-path}/atc/roboto-v30-latin-900.woff') format('woff');
}