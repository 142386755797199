[data-cmp="mediaViewer"] {
  [default][max] {
    div {
      flex: unset;
      flex-basis: unset;
    }
  }

  img:not(.positioned-overlay-anchor) {
    border-radius: $border-radius-base;
    object-fit: cover;
    cursor: pointer;
  }

  p {
    margin: 0;
  }
}

div[data-automation="overlay-container"] div[data-automation="scrollcontent"] {
  img {
    max-height: 500px;
  }
}

div[data-automation="scrollcontent"] div .media-image-wrapper {
  background: transparent !important;
  -moz-box-align: center;
  align-items: center;
  overflow: hidden;
  -moz-box-pack: center;
  justify-content: center;
  width: 100%;
  display: flex;

  img {
    max-height: 90vh;
  }
}

@media (max-width: $screen-xs-max) {
  div[data-automation="overlay-body"] {
    padding-top: 16px;
  }
}

[data-cmp="mediaShowcase"] {
  &.single-slot {
    div.carousel-cell:first-of-type {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .main-slot {
        div {
          width: auto !important;

          img {
            display: block;
            max-height: 100%;
            height: auto !important;
            width: auto !important;
          }
        }
      }
    }
  }

  .main-slot {
    margin-right: 3px;
  }

  .secondary-slot {
    display: flex;
    align-items: stretch;
    flex: 1;
    position: relative;
    text-align: center;
    margin-bottom: 3px;
    margin-right: 3px;
  }

  .media-image-wrapper {
    border-radius: $border-radius-base;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .media-gallery-image {
    cursor: pointer;

    &:focus {
      filter: alpha(opacity=60);
      opacity: 0.6;
    }
  }

  .media-360spin {
    height: 30px !important;
    line-height: 14px !important;
  }

  .media-play-circle {
    position: absolute;
    color: $white;
    font-size: 6rem;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    pointer-events: none;
  }

  // TODO: why isn't this using the `label bsStyle="inverse"` component?
  .badge {
    background-color: $black;
    border-radius: 4px;
    color: $white;
    height: 22px;
    padding: 0 8px;
    position: absolute;
    font-size: 10px;
    display: inline-block;
    line-height: 21px;
    margin: 3px;
    opacity: 0.75;
    pointer-events: none;
  }

  .thumbnail-overlay {
    background-color: rgb(0 0 0 / 80%);
    color: $white;
    cursor: pointer;
    position: absolute;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 4px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  @media (width >=1000px) and (width <=1280px) {
    .carousel-cell .reverse {
      flex-direction: row-reverse;
    }
  }
}

// Handles max-height as percent of viewport height to allow carousel
// to correctly size when images are a mix of aspect ratios; 
// leaves room for top/bottom controls
[data-cmp="fullscreenViewer"] {
  .media-gallery-image {
    max-height: calc(100vh - 100px);
  }
}