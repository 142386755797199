// Sets an application-wide focusable area so that keyboard navigation
// brings elements into visibility without being covered by sticky elements
// at the top and bottom of the window
html {
      scroll-padding: 100px auto 70px;

      @include media-breakpoint-up('sm') {
            scroll-padding: 70px auto 100px;
      }
}

// Forces the main container to be full viewport height 
// to help with CLS when transitioning pages
main {
      min-height: 100vh;
}