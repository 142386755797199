#page-overlay-container {
    position: fixed; 
    display: flex;
    width: 100%;
    height: 100%;  
    inset: 0; 
    background-color: rgb(255 255 255 / 60%);
    z-index: 100000;
    justify-content: center;
    align-items: center;
  
    .loader {
      width:  120px; 

      @media (max-width: $screen-xs-max) {
          width: 72px;
        }
    }
  }